import React, { useContext } from "react"
import { AuthContext } from "../providers/auth"
import { Switch, Route, Redirect } from "react-router-dom"
import { auth } from "../firebase"

/**
 * Route components
 */
import Error404 from "../../containers/errors/404"
import Clients from "../../containers/clients/clients"
import Client from "../../containers/client/client"
import Targets from "../../containers/targets/targets"
import Enquiries from "../../containers/enquiries/enquiries"
import Bookings from "../../containers/bookings/bookings"
import BookingWrap from "../../containers/booking/booking"
import StaffEnquiries from "../../containers/staff/staff"
import Stats from "../../containers/stats/stats"
import Conversions from "../../containers/conversions/conversions"
import Balances from "../../containers/balances/balances"
import Suppliers from "../../containers/suppliers/suppliers"
import Travellers from "../../containers/travellers/travellers"
import Partners from "../../containers/partners/partners"
import Partner from "../../containers/partner/partner"
import Leaderboard from "../../containers/leaderboard/leaderboard"
import Internal from "../../containers/internal/internal"
import Data from "../../containers/data/data"
import Cancellations from "../../containers/cancellations/cancellations"
import Deposits from "../../containers/deposits/deposits"
import Holidays from "../../containers/cms/holidays/holidays"
import EditHoliday from "../../containers/cms/holidays/edit/edit"
import Quoting from "../../containers/quoting/quoting"
import Courses from "../../containers/cms/courses/courses"
import EditCourse from "../../containers/cms/courses/edit/edit"
import DeletedEnquiries from "../../containers/deleted-enquiries/deleted-enquiries"
import EnquiryAssignments from "../../containers/enquiry-assignments/enquiry-assignments"
import MyQuotes from "../../containers/my-quotes/my-quotes"
import Flights from "../../containers/flights/flights"

/**
 * Returns the routes for staff on the app
 */
function PageRoutes() {
	/**
	 * Deconstruct the user object from the auth context
	 */
	const { user } = useContext(AuthContext)

	return (
		<Switch>
			{/* Admin route for targets */}
			{user?.is_admin && (
				<Route
					path="/targets"
					component={Targets}
				/>
			)}

			{/* Admin route for internal settings */}
			{user?.is_admin && (
				<Route
					path="/internal"
					component={Internal}
				/>
			)}

			{/* Admin route for data settings */}
			{user?.is_admin && (
				<Route
					path="/data"
					component={Data}
				/>
			)}

			{/* Admin route for viewing deleted enquiries */}
			{["CGOHCdUwFAOe13OSTY2D9qdO2LX2", "iUlTXjfx1OZEPBhPosh6rJt3CKe2", "7GFYd2EvA9OiZxcufgbz5CRcssu1", "fM61i6PTzzZPtCGolcx870r9cCo2", "L334Rwj0YiRWSuCZLlixoBbsxwl2"].includes(
				auth.currentUser.uid
			) && (
				<Route
					path="/deleted-enquiries"
					component={DeletedEnquiries}
				/>
			)}

			{/* Admin route for viewing deleted enquiries */}
			{["CGOHCdUwFAOe13OSTY2D9qdO2LX2", "iUlTXjfx1OZEPBhPosh6rJt3CKe2", "7GFYd2EvA9OiZxcufgbz5CRcssu1", "fM61i6PTzzZPtCGolcx870r9cCo2", "L334Rwj0YiRWSuCZLlixoBbsxwl2"].includes(
				auth.currentUser.uid
			) && (
				<Route
					path="/enquiry-assignments"
					component={EnquiryAssignments}
				/>
			)}

			{/* App page routes for staff accounts */}
			<Route
				path="/clients"
				component={Clients}
			/>
			<Route
				path="/client/:id"
				component={Client}
			/>
			<Route
				path="/enquiries"
				component={Enquiries}
			/>
			<Route
				path="/bookings"
				component={Bookings}
			/>
			<Route
				path="/booking/:id"
				component={BookingWrap}
			/>
			<Route
				path="/staff"
				component={StaffEnquiries}
			/>
			<Route
				path="/stats"
				component={Stats}
			/>
			<Route
				path="/flights"
				component={Flights}
			/>
			<Route
				path="/conversions"
				component={Conversions}
			/>
			<Route
				path="/balances"
				component={Balances}
			/>
			<Route
				path="/suppliers"
				component={Suppliers}
			/>
			<Route
				path="/travellers"
				component={Travellers}
			/>
			<Route
				path="/my-quotes"
				component={MyQuotes}
			/>

			{[
				"CGOHCdUwFAOe13OSTY2D9qdO2LX2",
				"iUlTXjfx1OZEPBhPosh6rJt3CKe2",
				"7GFYd2EvA9OiZxcufgbz5CRcssu1",
				"fM61i6PTzzZPtCGolcx870r9cCo2",
				"L334Rwj0YiRWSuCZLlixoBbsxwl2",
				"9ChsmNaiYkdQGjm3JbEbqY6mIbR2",
				"Z6EM3kFyJ8QiJMzMsYm6TwrWaUH3",
				"FbeBRh8bIGb9L6aRLLJSttvutQ33",
				"p4VG2EgFFQS8fsaBc0d9peNWaD52",
			].includes(auth.currentUser.uid) && (
				<Route
					path="/partners"
					component={Partners}
				/>
			)}

			{[
				"CGOHCdUwFAOe13OSTY2D9qdO2LX2",
				"iUlTXjfx1OZEPBhPosh6rJt3CKe2",
				"7GFYd2EvA9OiZxcufgbz5CRcssu1",
				"fM61i6PTzzZPtCGolcx870r9cCo2",
				"L334Rwj0YiRWSuCZLlixoBbsxwl2",
				"9ChsmNaiYkdQGjm3JbEbqY6mIbR2",
				"Z6EM3kFyJ8QiJMzMsYm6TwrWaUH3",
				"FbeBRh8bIGb9L6aRLLJSttvutQ33",
				"p4VG2EgFFQS8fsaBc0d9peNWaD52",
			].includes(auth.currentUser.uid) && (
				<Route
					path="/partner/:collection/:id"
					component={Partner}
				/>
			)}

			<Route
				path="/leaderboard"
				component={Leaderboard}
			/>
			<Route
				path="/cancellations"
				component={Cancellations}
			/>
			<Route
				path="/deposits"
				component={Deposits}
			/>
			<Route
				path="/quoting"
				component={Quoting}
			/>

			{[
				"CGOHCdUwFAOe13OSTY2D9qdO2LX2",
				"iUlTXjfx1OZEPBhPosh6rJt3CKe2",
				"7GFYd2EvA9OiZxcufgbz5CRcssu1",
				"fM61i6PTzzZPtCGolcx870r9cCo2",
				"L334Rwj0YiRWSuCZLlixoBbsxwl2",
				"9ChsmNaiYkdQGjm3JbEbqY6mIbR2",
				"Z6EM3kFyJ8QiJMzMsYm6TwrWaUH3",
				"FbeBRh8bIGb9L6aRLLJSttvutQ33",
				"p4VG2EgFFQS8fsaBc0d9peNWaD52",
			].includes(auth.currentUser.uid) && (
				<Route
					path="/manage/holidays"
					component={Holidays}
				/>
			)}

			{[
				"CGOHCdUwFAOe13OSTY2D9qdO2LX2",
				"iUlTXjfx1OZEPBhPosh6rJt3CKe2",
				"7GFYd2EvA9OiZxcufgbz5CRcssu1",
				"fM61i6PTzzZPtCGolcx870r9cCo2",
				"L334Rwj0YiRWSuCZLlixoBbsxwl2",
				"9ChsmNaiYkdQGjm3JbEbqY6mIbR2",
				"Z6EM3kFyJ8QiJMzMsYm6TwrWaUH3",
				"FbeBRh8bIGb9L6aRLLJSttvutQ33",
				"p4VG2EgFFQS8fsaBc0d9peNWaD52",
			].includes(auth.currentUser.uid) && (
				<Route
					path="/manage/holiday/:id"
					component={EditHoliday}
				/>
			)}

			{[
				"CGOHCdUwFAOe13OSTY2D9qdO2LX2",
				"iUlTXjfx1OZEPBhPosh6rJt3CKe2",
				"7GFYd2EvA9OiZxcufgbz5CRcssu1",
				"fM61i6PTzzZPtCGolcx870r9cCo2",
				"L334Rwj0YiRWSuCZLlixoBbsxwl2",
				"9ChsmNaiYkdQGjm3JbEbqY6mIbR2",
				"Z6EM3kFyJ8QiJMzMsYm6TwrWaUH3",
				"FbeBRh8bIGb9L6aRLLJSttvutQ33",
				"p4VG2EgFFQS8fsaBc0d9peNWaD52",
			].includes(auth.currentUser.uid) && (
				<Route
					path="/manage/courses"
					component={Courses}
				/>
			)}

			{[
				"CGOHCdUwFAOe13OSTY2D9qdO2LX2",
				"iUlTXjfx1OZEPBhPosh6rJt3CKe2",
				"7GFYd2EvA9OiZxcufgbz5CRcssu1",
				"fM61i6PTzzZPtCGolcx870r9cCo2",
				"L334Rwj0YiRWSuCZLlixoBbsxwl2",
				"9ChsmNaiYkdQGjm3JbEbqY6mIbR2",
				"Z6EM3kFyJ8QiJMzMsYm6TwrWaUH3",
				"FbeBRh8bIGb9L6aRLLJSttvutQ33",
				"p4VG2EgFFQS8fsaBc0d9peNWaD52",
			].includes(auth.currentUser.uid) && (
				<Route
					path="/manage/course/:id"
					component={EditCourse}
				/>
			)}

			{/* Page redirects for staff accounts */}
			<Redirect
				from="/"
				to="/clients"
				exact
			/>
			<Redirect
				from="/login"
				to="/clients"
				exact
			/>

			{/* Error pages */}
			<Route
				path="*"
				component={Error404}
			/>
		</Switch>
	)
}

export default PageRoutes
